import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { PerspectiveCamera, useGLTF, Html, Text, DeviceOrientationControls } from '@react-three/drei';
import { isMobile } from 'react-device-detect';

function Model({ url, pos }) {
  const gltf = useGLTF(url, true);
  const ref = useRef();

  useEffect(() => {
    if (!isMobile) {
      ref.current.lookAt(...pos);
    }

    // Make model transparent and apply glass effect
    ref.current.traverse((node) => {
      if (node.isMesh) {
        node.material.opacity = 0.5;  // Adjust this for desired opacity
        node.material.transparent = true;
        node.material.envMapIntensity = 1;
        node.material.reflectivity = 1;
        node.material.shininess = 100;
      }
    });
  }, [pos]);

  return (
    <group ref={ref}>
      <primitive object={gltf.scene} dispose={null} />
      {isMobile ? <DeviceOrientationControls /> : null}
    </group>
  );
}

function AnimatedText() {
  const mesh = useRef();
  const fontSize = window.innerHeight / 100;  // Full height text

  useFrame(({ clock }) => {
    mesh.current.position.x = window.innerWidth / 100 - (clock.getElapsedTime() % 40);  // Adjust this for text speed
    // Add some glitch effect
    mesh.current.position.y = Math.sin(clock.getElapsedTime()) * 0.1;
    mesh.current.material.color.r = Math.random();
    mesh.current.material.color.g = Math.random();
    mesh.current.material.color.b = Math.random();
  });

  return (
    <Text ref={mesh} fontSize={fontSize} color="black" anchorX="center" anchorY="middle" fontWeight="bold">
      NEWSHAPE
    </Text>
  );
}

function Scene() {
  const [pos, setPos] = useState([0, 0, 0]);

  useEffect(() => {
    function updateMouse(e) {
      const xPos = (e.clientX - window.innerWidth / 2) / 100;
      const yPos = -(e.clientY - window.innerHeight / 2) / 100;
      setPos([xPos, yPos, 5]);
    }

    window.addEventListener('mousemove', updateMouse);

    return () => window.removeEventListener('mousemove', updateMouse);
  }, []);

  return (
    <Canvas style={{ width: '100vw', height: '100vh' }}>
      <Suspense fallback={<Html>Loading...</Html>}>
        <PerspectiveCamera makeDefault position={[0, 3, 10]} fov={75} aspect={window.innerWidth / window.innerHeight} near={0.1} far={1000} />
        <ambientLight intensity={1.7} />
        <pointLight position={[-16, 2, 2]} intensity={1} distance={100} decay={1} />
        <pointLight position={[-30, 2, 2]} intensity={1} distance={100} decay={1} />
        <spotLight position={[12, 12, 12]} intensity={1} distance={100} angle={2} />
        <Model url="./vr.glb" pos={pos} />
        <AnimatedText />
      </Suspense>
    </Canvas>
  );
}

export default function App() {
  return (
    <div style={{width: '100vw', height: '100vh', overflow: 'hidden'}}>
      <Scene />
    </div>
  );
}
